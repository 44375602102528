<template>
	<div
		class="full-height flex-column"
	>
        <div class="justify-space-between items-center">
            <div>총 {{ search.total_count }}개</div>
            <Search
                class=" "
                :search="search"
                :option="search_option"

                @reset="reset"
                @click="getSearch"
            >
                <select
                    slot="add"
                    class="pa-5-10 mr-10"

                    v-model="search.branchType"
                    @change="getSearch(1)"
                >
                    <option value="">영업단 선택</option>
                    <template
                        v-for="(agency, index) in codes.agency_type"
                    >
                        <option
                            v-if="index > 0 && index <= codes.agency_type.length - 2 && index > item.branchType"
                            :key="'agency_' + index"
                            :value="agency.code"
                        >{{ agency.name }}</option>
                    </template>
                </select>
            </Search>
        </div>

		<div class=" pa-10 bg-white full-height flex-column overflow-y-auto">

			<table
				v-if="items.length > 0"
				class=" table table-even top-line-identify"
			>
				<colgroup>
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>구분</th>
					<th>아이디</th>
					<th>상호</th>
					<th>사업자 구분</th>

					<th>상태</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{  item.idx }}</td>
					<td>{{  item.branch_name }}</td>
					<td>{{  item.id }}</td>
					<td>{{  item.shopName }}</td>
					<td>{{ item.business_name }}</td>

					<td>{{  item.state_name }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center  top-line-identify"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";

export default {
	name: 'AgencySubList',
	components: {Search, Pagination},
	props: ['user', 'item', 'targetIdx']
	,data: function(){
		return {
			program: {
				name: '하위 조직'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
				,branchType: ''
                ,total_count: 0
			}
			,search_option: {
				search_type: [
					{ name: '상호', column: 'name'}
				]
			}
			,items: []
			,is_excel: false
			,excel_data: {
				name: '영업점 목록'
				,header: [
					{ key: 0, name: 'NO', column: 'no'}
					,{ key: 0, name: '구분', column: 'wDate'}
					,{ key: 0, name: '아이디', column: 'name'}
					,{ key: 0, name: '상호', column: 'card_num'}
					,{ key: 0, name: '사업자 구분', column: 'payment'}
					,{ key: 0, name: '등록일', column: 'amount'}
					,{ key: 0, name: '상태', column: 'cancel_date'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.agency_type.length; i++){
					if(item.branchType == self.codes.agency_type[i].code){
						item.branch_name = self.codes.agency_type[i].name
						break
					}
				}

				for(let i = 0; i < self.codes.P02.length; i++){
					if(item.businessType == self.codes.P02[i].TOTAL_CODE){
						item.business_name = self.codes.P02[i].code_name
						break
					}
				}

				for(let i = 0; i < self.codes.status_agency.length; i++){
					if(item.state == self.codes.status_agency[i].code){
						item.state_name = self.codes.status_agency[i].name
						item.state_color = self.codes.status_agency[i].color
						break
					}
				}

				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'branch/lower/' + this.item.idx
					,data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.$set(this.search, 'total_count', result.data.totalCount)
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){

			this.$router.push({ name: 'AgencyDetail', params: { idx: item.idx}})
		}
		,toSub: function(item){
			this.item_sub = item
			this.is_modal = true
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}
			this.getData()
		}
        ,reset: function(){
            this.search = {
                page: 1
                ,size: 10
                ,search_type: ''
                ,keyword: ''
                ,branchType: ''
                ,total_count: 0
            }
            this.getData()
        }
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        console.log(this.item)
		this.getData()
	}
}
</script>